@font-face {
  font-family: 'FADBrand';
  src: url('resources/fonts/Georgia-Bold.woff') format('woff'), url('resources/fonts/Georgia-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.App {
}

.App-no-underline {
  text-decoration: none !important
}

.fadPrimary {
  color: #0a2647 !important
}

.fadSecondary {
  color: #e36f77 !important
}

.btn-fadPrimary {
  background-color: #0a2647 !important;
  color: #ffffff !important
}
.bg-fadPrimary {
  background-color: #0a2647 !important;
  color: #ffffff !important
}

.btn-fadSecondary {
  background-color: #e36f77 !important;
  color: #ffffff !important
}

.bg-fadSecondary {
  background-color: #e36f77 !important;
  color: #ffffff !important
}

.fad-callout {
  background-color:#fff3cd;
  border-top-color:#ffeeba;
  border-right-color:#ffeeba;
  border-bottom-color:#ffeeba;
  color:#856404
}

.fad-semiFullWidth {
  width: min(100%, 300px);
}

.fad-podcastThumbWidth {
  width: 96px;
  height: 96px;
}

.fad-lineHeight12 {
  line-height: 1.2;
}

.fad-px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.fad-mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.fad-ml-15 {
  margin-left: 15px;
}

.fad-mr-15 {
  margin-right: 15px;
}

.fad-mb-10 {
  margin-bottom: 10px;
}

.fad-thumbnail {
  width: 48px;
  height: 48px;
}

.fad-image-button {
  width: 20px;
  height: 20px;
}

.fad-image-button-24 {
  width: 24px;
  height: 24px;
}

.fad-small-image-button {
  width: 10px;
  height: 10px;
}

.fad-small-image-line {
  line-height: 12px;
}

.fad-md-image-button {
  width: 30px;
  height: 30px;
}

.fad-fa-size-md {
  font-size: 30px;
}

.fad-md-image-button-2 {
  width: 64px;
  height: 64px;
}

.fad-large-image-button {
  width: 80px;
  height: 80px;
}

.fad-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fad-category-icon {
  width: 36px;
  height: 36px;
}

.fad-player {
  width: 100%;
  height: auto;
}

.fad-accent {
  color: #ec2227;
}

.text-transparent {
  color: rgba(0, 0, 0, 0);
}

.fad-footer {
  left: 0;
}

#fadBrand {
  font-family: 'FADBrand', sans-serif;
}

html {
  scroll-behavior: smooth;
}